<template>
  <v-expansion-panel>
    <itinerary-header
      :itemID="itemID"
      :title="title"
      :totalPrice="totalPrice"
      :startDTM="startDTM"
      :endDTM="endDTM"
      :duration="duration"
      :icon-color="itemColor(record)"
      :icon="itemIcon(record)"
      :eventType="getEventType(record)"
      :directionsLink="formatDirectionsLink"
      :record="record"
    />
  </v-expansion-panel>
</template>

<script>
import ItineraryHeader from '@/components/itinerary-trip-rows/ItineraryHeader'
import itineraryService from '@/helpers/itineraryService'
import placeSearch from '@/helpers/placeSearch'

export default {
  name: 'ItineraryCruiseRow',
  components: {
    ItineraryHeader,
  },
  data() {
    return {
      portName: ''
    }
  },
  props: {
    record: Object,
    directionsLink: String,
    readonly: { type:Boolean, required: true },
  },
  computed: {
    itemDetails() {
      return this.record?.cruise
    },
    isEmbark() {
      return this.record?.header?.embark
    },
    isDisembark() {
      return this.record?.header?.disembark
    },
    itemID() {
      return this.record?.header?.itemID
    },
    title() {
      if (this.isEmbark)
        return this.itemDetails?.title
      else
        return this.port
    },
    totalPrice() {
      //only show price for embarkation event
      return this.isEmbark  ? this.itemDetails?.totalPrice : {}
    },
    duration() {
      if (this.isEmbark) return (this.itemDetails?.cruiseDays.length - 1) + ' days cruise'
      if (this.isDisembark) return this.$t('Cruise_disembark')

      return 'Day ' + (this.record?.header?.dayIndex + 1)
    },
    startDTM() {
      return this.record?.header?.startDateTime
    },
    endDTM() {
      return this.record?.header?.endDateTime
    },
    port() {
      return this.portName || 'At sea'
    },
    formatDirectionsLink() {
      return this.isEmbark ? this.directionsLink : ''
    },
  },
  methods: {
    itemColor: itineraryService.itemColor,
    getEventType: itineraryService.getEventType,
    itemIcon: itineraryService.itemIcon,
  },
  watch: {
    itemDetails: {
      async handler() {
        const placeID = this.itemDetails?.cruiseDays?.at(this.record?.header?.dayIndex)?.cruiseDayAtPort?.port?.placeID
        if (placeID) {
          this.portName = (await placeSearch.lookupPlacesByIds([placeID]))?.at(0)?.name
        } else {
          this.portName = ''
        }
      }, immediate: true
    },
  }
}
</script>
