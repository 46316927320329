<template>
  <div data-test-name="page-sign-in">
    <authorization-page-common-wrapper
      :applyButtonCaption="$t('Sign_in')"
      :applyButtonEnabled="isFormValid && !isUserLogsIn"
      :headerButton="headerButton"
      @clickApply="signInUser"
      @clickHeaderButton="handleClickHeaderButton"
      data-test-name="container-sign-in"
    >
      <template v-slot:content>
        <v-col cols="12" class="py-0 px-0 mt-3" align="center">
          <v-text-field
            data-test-name="input-email"
            color="primary"
            base-color="primary"
            bg-color="#fff"
            type="email"
            email
            variant="outlined"
            prepend-inner-icon="$customEmail"
            :model-value="email.value"
            :label="email.label"
            :error-messages="emailErrors"
            @blur="validateEmail"
            @update:model-value="updateField('email', $event)"
            @keydown.enter="signInUser"
          />
        </v-col>
        <v-col cols="12" class="py-0 px-0 mt-3" align="center">
          <v-text-field
            variant="outlined"
            color="primary"
            base-color="primary"
            bg-color="#fff"
            prepend-inner-icon="$customLock"
            data-test-name="input-password"
            :autofocus="!!email.value"
            :model-value="password.value"
            :label="$t('Password')"
            :type="passwordInputType"
            :append-inner-icon="passwordAppendedIcon"
            :error-messages="passwordErrors"
            @click:append-inner="password.isValueVisible = !password.isValueVisible"
            @blur="validatePassword"
            @update:model-value="updateField('password', $event)"
            @keydown.enter="signInUser"
          />
        </v-col>
        <v-col cols="12" class="py-0 pb-4" justify="space-between">
          <v-row align="center" justify="space-between">
            <v-btn
              class="header-button text-caption"
              variant="text"
              color="primary"
              data-test-name="button-open-reset-password-page"
              @click="goToResetPasswordPage"
            >
              {{ $t('Reset_password') }}
            </v-btn>
          </v-row>
        </v-col>
      </template>
      <template v-slot:error>
        <v-col
          cols="12"
          align="center"
          class="pa-0"
        >
          <label class="text-red-lighten-4 text-body-2" data-test-name="text-error-message">
            {{ errorMessage }}
          </label>
          <v-btn
            v-if="!isAccountVerified"
            variant="text"
            color="primary"
            data-test-name="button-send-verification-email"
            @click="sendVerificationEmail"
          >
            {{ $t('Resend') }}
          </v-btn>
        </v-col>
      </template>
    </authorization-page-common-wrapper>
  </div>
</template>

<script>
import AuthorizationPageCommonWrapper from '@/components/authorization-page/AuthorizationPageCommonWrapper'
import cloneDeep from 'lodash.clonedeep'
import { email, currentPassword } from '@/helpers/userProfileFields'
import { checkFilled, checkCorrect, checkHasEmptySpace } from '@/helpers/inputValidators'
import { mapActions, mapGetters } from 'vuex'
import { signInUserWithEmailAndPassword, sendUserEmailVerification } from '@/helpers/firebase/firebase'
import { RouteNames } from '@/helpers/routerUtils'
import { redirectToQueryPath } from '@/mixins/RedirectionMixin'
import { useRoute } from 'vue-router'

export default {
  name: 'SignIn',
  components: {
    AuthorizationPageCommonWrapper
  },
  mixins: [redirectToQueryPath],
  data() {
    return {
      email: { },
      password: { },
      errorMessage: null,
      isUserLogsIn: false,
      isAccountVerified: true,
      route: useRoute(),
    }
  },
  computed: {
    ...mapGetters({
      isMobile: 'IS_MOBILE',
      isAnonymous: 'IS_ANONYMOUS'
    }),
    isFormValid() {
      return !!(this.email.value && !this.email.errors.length && this.password.value && !this.password.errors.length)
    },
    preparedEmail() {
      return (this.email.value || '').trim().toLowerCase()
    },
    emailErrors(){
      return this.email.blurred ? this.email.errors.map(message => this.$t(message)) : []
    },
    passwordAppendedIcon(){
      return this.password.isValueVisible ? 'mdi-eye-outline' : 'mdi-eye-off-outline'
    },
    passwordInputType(){
      return this.password.isValueVisible ? 'text' : 'password'
    },
    passwordErrors(){
      return this.password.blurred ? this.password.errors.map(message => this.$t(message)) : []
    },
  },
  created() {
    this.email = cloneDeep(email)
    this.password = cloneDeep(currentPassword)

    this.headerButton = {
      type: 'goToSignUpPage',
      description: this.$t('Do_not_have_account_question'),
      caption: this.$t('Sign_up'),
      callback: this.goToSignUpPage,
    }
    if(this.route.query.email) {
      this.email.value = this.route.query.email
    }
  },
  methods: {
    ...mapActions({
      userLogin: 'USER_LOGIN',
      pushAppNotification: 'PUSH_APP_NOTIFICATION',
    }),
    updateField(fieldName, value) {
      this[fieldName].value = value
      this[fieldName].blurred = false
      this.errorMessage = null
    },
    validateEmail(){
      const errors = []
      this.email.blurred = true
      errors.push(checkFilled(this.email))
      errors.push(checkCorrect(this.email))
      this.email.errors = errors.filter(error => !!error)
    },
    validatePassword(){
      const errors = []
      this.password.blurred = true
      errors.push(checkHasEmptySpace(this.password))
      errors.push(checkFilled(this.password))
      errors.push(checkCorrect(this.password))
      this.password.errors = errors.filter(error => !!error)
    },
    async signInUser() {
      this.isAccountVerified = true
      if(this.isFormValid) {
        this.isUserLogsIn = true
        try {
          const { user } = await signInUserWithEmailAndPassword(this.preparedEmail, this.password.value)
          if(user.emailVerified) {
            await this.userLogin(user)
          } else {
            this.isAccountVerified = false
            this.errorMessage = `${this.$t('Incorrect_login_message')} ${this.$t('Non_verified_email_message')}`
          }
        } catch(error) {
          this.errorMessage = this.$t('Incorrect_login_message')
        } finally {
          this.isUserLogsIn = false
        }
      }
    },
    handleClickHeaderButton(callback) {
      callback()
    },
    goToSignUpPage() {
      this.$router.push({name: RouteNames.SIGN_UP, query: this.route.query})
    },
    goToResetPasswordPage() {
      this.$router.push({name: RouteNames.RESET_PASSWORD})
    },
    async sendVerificationEmail() {
      this.isAccountVerified = true
      this.errorMessage = null
      try {
        await sendUserEmailVerification(this.route.query)
        this.pushAppNotification({
          name: 'resendEmail',
          content: this.$t('Resend_email_message', { email: this.preparedEmail } ),
          color: 'primary'
        })
      } catch(error) {
        this.errorMessage = this.$t('Incorrect_Email_OR_Password')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .save-authorization{
    display: flex;
    align-items: center;
  }
</style>
