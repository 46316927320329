<template>
  <v-card elevation="0">
    <v-row :class="{ 'd-none': isReadOnly }" class="mx-0 pt-4 tabs-container">
      <div class="text-primary font-weight-medium text-uppercase my-4 ml-4">
        {{ dialogLabel }}
      </div>
    </v-row>
    <v-card-text class="event-content">
      <v-row class="mx-0 mt-10 pt-1 pt-sm-4 my-2">
        <v-col cols="12" class="mt-2 mb-2 mt-sm-0 mb-0 pb-0 px-0">
          <v-text-field
            :readonly="isReadOnly || isDayAtPortOrSea || isDisembark"
            :model-value="title"
            @update:model-value="updateTitle($event)"
            :label="$t('Title')"
            :class="{ 'no-pointer-events': isReadOnly }"
            variant="outlined"
            bg-color="#fff"
            base-color="primary"
            color="primary"
            hide-details
            maxLength="200"
          />
        </v-col>
      </v-row>
      <v-row class="ma-0" v-if="isDayAtPortOrSea">
        <v-col cols="6" sm="6" class="px-0 mx-0 my-n8 my-sm-n8 ml-n2 ml-sm-n2">
          <v-checkbox
            :model-value="isDayAtSea"
            @update:model-value="updateDayAtSea($event)"
            :readonly="isReadOnly"
            hide-details
            color="primary"
            label="Day at sea?"
            class="text-body-2 round-trip-text">
          </v-checkbox>
        </v-col>
      </v-row>
      <v-row class="ma-0" v-if="isDayAtPortOrSea && !isDayAtSea">
        <v-col cols="6" :sm="6" class="px-0 pr-sm-2">
          <place-input
            :value="dayPortPlaceID"
            :label="startPortLabel"
            :readonly="isReadOnly"
            min-width="320"
            name="placeInputPort"
            content-class="placeInputPort"
            variant="outlined"
            @updateLocation="updatePortPoint"
          />
        </v-col>
      </v-row>
      <v-row class="ma-0 px-sm-0" v-if="!isDayAtPortOrSea">
        <v-col cols="12" :sm="isRoundTrip ? 6 : 4" class="px-0 pr-sm-2" v-if="createNew || isEmbark">
          <place-input
            :value="startPlaceID"
            :label="startPortLabel"
            :readonly="isReadOnly || isDisembark"
            min-width="320"
            name="placeInputEmbarkPort"
            content-class="placeInputEmbarkPort"
            variant="outlined"
            @updateLocation="updateStartPoint"
          />
        </v-col>
        <v-col v-if="!isRoundTrip" cols="12" sm="4" class="px-0" data-test-id="col-arrival__input">
          <place-input
            :value="endPlaceID"
            :label="endPortLabel"
            :readonly="isReadOnly || isDisembark"
            min-width="320"
            name="placeInputDisembarkPort"
            content-class="placeInputDisembarkPort"
            variant="outlined"
            @updateLocation="updateEndPoint"
          />
        </v-col>
        <v-col :cols="isRoundTrip ? 6 : 4" v-if="!$vuetify.display.xs" class="pr-sm-0">
          <passengers-selector
            :value="travelers"
            :readonly="isReadOnly || isDayAtPortOrSea || isDisembark"
            @input="travelers = $event"
            name="generate-custom-event"
            class="passengers-selector"
            v-if="createNew || isEmbark"
          >
            <template #activator="{ props }">
              <v-text-field
                :model-value="totalTravelersNumber"
                :label="$t('Travelers') + '*'"
                class="pa-0 ma-0 trigger-change-travelers"
                :class="{ activator: !isReadOnly }"
                variant="outlined"
                color="primary"
                base-color="primary"
                bg-color="#fff"
                readonly
                hide-details
                prepend-inner-icon="$customTravelers"
                v-bind="props"
              />
            </template>
          </passengers-selector>
        </v-col>
      </v-row>

      <v-row class="ma-0" v-if="!isDayAtPortOrSea && !isDisembark">
        <v-col cols="12" class="px-0 mx-0 my-n8 my-sm-n8 ml-n2 ml-sm-n2">
          <v-checkbox
            :model-value="isRoundTrip"
            @update:model-value="updateRoundTrip($event)"
            :readonly="isReadOnly"
            hide-details
            color="primary"
            :label="'Arrival port is the same as departure'"
            class="text-body-2 round-trip-text">
          </v-checkbox>
        </v-col>
      </v-row>

      <v-row class="ma-0 px-sm-0">
        <v-col cols="6" sm="3" class="mb-0 pb-0 px-0 pr-2 pr-sm-2">
          <date-picker
            :model-value="startDate"
            :disabled="!startPlaceID"
            :readonly="isReadOnly || isDayAtPortOrSea || isDisembark"
            variant="outlined"
            :label="startDateLabel"
            @update:model-value="updateStartDate"
            min-width="320"
            name="EmbarkDate"
            hide-details="auto"
          />
          <span v-if="!startPlaceID">{{ $t('Pick_departure') }}</span>
        </v-col>
        <v-col cols="6" sm="3" class="mb-0 pb-0 pl-2 pr-0 pl-sm-2 pr-sm-2">
          <time-picker
            :model-value="startTime"
            :label="startTimeLabel"
            :disabled="!startPlaceID || !startDate"
            :readonly="isReadOnly || isDisembark"
            @update:model-value="updateStartTime"
            hide-details="auto"
          />
          <span v-if="!startPlaceID">{{ $t('Pick_departure') }}</span>
        </v-col>
        <v-col cols="6" sm="3" class="mb-0 pb-0 px-0 pr-2 pl-sm-2 pr-sm-2">
          <date-picker
            :model-value="endDate"
            :min-date="minDisembarkDateTime"
            :disabled="!endPlaceID || !startDate"
            :readonly="isReadOnly || isDayAtPortOrSea || isDisembark"
            :label="endDateLabel"
            @update:model-value="updateEndDate"
            :rules="endDateRules"
            min-width="320"
            name="DisembarkDate"
            hide-details="auto"
          />
          <span v-if="!endPlaceID">{{ $t('Pick_arrival') }}</span>
        </v-col>
        <v-col cols="6" sm="3" class="mb-0 pb-0 px-0 pl-2 pl-sm-2">
          <time-picker
            :model-value="endTime"
            :label="endTimeLabel"
            :disabled="!endPlaceID || !endDate"
            :readonly="isReadOnly || isDisembark"
            :rules="endTimeRules"
            @update:model-value="updateEndTime"
            hide-details="auto"
          />
          <span v-if="!endPlaceID">{{ $t('Pick_arrival') }}</span>
        </v-col>
      </v-row>

      <v-row class="mx-0">
        <v-col cols="6" sm="3" class="mb-0 pb-0 px-0 pr-2 pr-sm-2">
          <v-text-field
            v-model="cruiseCompanyName"
            :label="$t('Cruise_company') + '*'"
            :readonly="isReadOnly || isDayAtPortOrSea || isDisembark"
            :min="0"
            class="pa-0 ma-0"
            color="primary"
            base-color="primary"
            bg-color="#fff"
            variant="outlined"
            hide-details
          />
        </v-col>
        <v-col cols="6" sm="3" class="pl-2 pl-sm-2 pr-sm-2 pr-0">
          <v-text-field
            v-model="cruiseShipName"
            :label="$t('Cruise_ship_name')"
            :readonly="isReadOnly || isDayAtPortOrSea || isDisembark"
            :min="0"
            class="pa-0 ma-0"
            variant="outlined"
            color="primary"
            base-color="primary"
            bg-color="#fff"
            hide-details
          />
        </v-col>
        <v-col cols="6" sm="3" class="mb-0 pb-0 px-0 pl-sm-2 pr-2 pr-sm-2">
          <v-text-field
            v-model="cabinClass"
            :label="$t('Cabin_class')"
            :readonly="isReadOnly || isDayAtPortOrSea || isDisembark"
            :min="0"
            class="pa-0 ma-0"
            color="primary"
            base-color="primary"
            bg-color="#fff"
            variant="outlined"
            hide-details
          />
        </v-col>
        <v-col cols="6" sm="3" class="pl-2 pl-sm-2 pr-0">
          <v-text-field
            v-model="cabinNumber"
            :label="$t('Cabin_number')"
            :readonly="isReadOnly || isDayAtPortOrSea || isDisembark"
            :min="0"
            class="pa-0 ma-0"
            color="primary"
            base-color="primary"
            bg-color="#fff"
            variant="outlined"
            hide-details
          />
        </v-col>
      </v-row>

      <v-row class="mx-0" v-if="$vuetify.display.xs">
        <v-col cols="6" class="pl-0 pl-sm-1 pr-2 mb-0 mb-sm-3">
          <passengers-selector
            :value="travelers"
            :readonly="isReadOnly || isDayAtPortOrSea || isDisembark"
            @input="travelers = $event"
            name="generate-custom-event"
            class="passengers-selector"
            v-if="createNew || isEmbark"
          >
            <template #activator="{ props }">
              <v-text-field
                :model-value="totalTravelersNumber"
                :label="$t('Travelers') + '*'"
                class="pa-0 ma-0 trigger-change-travelers"
                :class="{ activator: !isReadOnly }"
                color="primary"
                base-color="primary"
                bg-color="#fff"
                variant="outlined"
                readonly
                hide-details
                prepend-inner-icon="$customTravelers"
                v-bind="props"
              />
            </template>
          </passengers-selector>
        </v-col>
        <v-col cols="6" sm="3">
          <event-booking
            :isBooked="isBooked"
            :readonly="isReadOnly || isDayAtPortOrSea || isDisembark"
            @updateBooked="isBooked = !isBooked"
            class="d-flex justify-end"
          />
        </v-col>
      </v-row>

      <v-row class="mx-0">
        <v-col cols="6" class="pl-0 pr-0 pr-sm-2">
          <v-select
            prepend-inner-icon="$customCurrency"
            v-model="totalPrice.currency"
            @update:model-value="updateCurrency"
            :label="$t('Currency')"
            :readonly="isReadOnly || isDayAtPortOrSea || isDisembark"
            :items="currencyList"
            class="pa-0 ma-0"
            color="primary"
            base-color="primary"
            bg-color="#fff"
            variant="outlined"
            hide-details
          />
        </v-col>
        <v-col cols="6" md="3" class="px-0 px-sm-3 pl-3">
          <v-currency-field
            :model-value="Number(totalPrice?.amount)"
            @update="updateAmount"
            variant="outlined"
            class="pa-0 ma-0"
            color="primary"
            :label="`${$t('Total_price')}`"
            :readonly="isReadOnly || isDayAtPortOrSea || isDisembark"
          />
        </v-col>
        <v-col cols="6" sm="3" v-if="!$vuetify.display.xs">
          <event-booking
            :isBooked="isBooked"
            :readonly="isReadOnly || isDayAtPortOrSea || isDisembark"
            @updateBooked="isBooked = !isBooked"
            class="d-flex justify-end"
          />
        </v-col>
      </v-row>

      <v-row class="ma-0 mb-14">
        <v-col cols="12" class="pt-0 px-0 px-sm-0">
          <v-textarea
            v-model="notes"
            :label="$t('Notes')"
            :auto-grow="false"
            :readonly="isReadOnly"
            class="pa-0 ma-0"
            color="primary"
            base-color="primary"
            bg-color="#fff"
            rows="3"
            variant="outlined"
            no-resize
            hide-details="auto"
            :rules="[rules.validNotes]"
          />
        </v-col>
      </v-row>
    </v-card-text>

    <event-popup-buttons
      :submit-button-disabled="!isValidForm"
      :submit-button-loading="isSubmitButtonLoading"
      :delete-button-visible="!createNew && isEmbark"
      :readonly="isReadOnly"
      @submit="onClickSave"
      @cancel="onClickCancel"
      @delete="isDialogToDeleteEventVisible = true"
    />

    <common-popup
      v-if="isDialogToDeleteEventVisible"
      :body-content="$t('Delete_this_event')"
      :confirm-label="$t('Delete')"
      @confirm="onDeleteEvent"
      @cancel="isDialogToDeleteEventVisible = false"
      @close="isDialogToDeleteEventVisible = false"
    />
  </v-card>
</template>

<script>
import { eventFormMainStructure } from '@/mixins/EventFormMixin'
import commonAPIs from '@/helpers/itineraryServices/commonAPIs'
import {
  convertAmountToServerFormat,
  convertAmountToUIFormat,
  getDatesAndTimeAsISOString
} from '@/helpers'
import VCurrencyField from '@/components/VCurrencyField'
import cruise from '@/helpers/itineraryServices/cruise'
import datetime from '@/helpers/datetime'
import { logAppError } from '@/logger'
import checkEquality from 'fast-deep-equal/es6'

export default {
  name: 'AddCruiseForm',
  emits: ['updateTitle', 'cancel', 'save'],
  components: {
    VCurrencyField,
  },
  mixins: [ eventFormMainStructure ],
  data() {
    return {
      startPoint: null,
      endPoint: null,
      cabinClass: '',
      cabinNumber: '',
      cruiseShipName: '',
      cruiseCompanyName: '',
      isDayAtSea: false,
      isRoundTrip: true,
      cruiseDays: [],
      dayPort: '',
      minDisembarkDateTime: null,
      dateTimeValidationResult: true,
      dateTimeRules: {
        dateTime: () => this.dateTimeValidationResult || this.$t('Before_Start_Error'),
      },
    }
  },
  created() {
    this.isRoundTrip = (this.startPlaceID === this.endPlaceID)
  },
  computed: {
    isDirty() {
      if (!this.initialRecord) return false
      if (this.isDayAtPortOrSea
        && (!checkEquality(this.dayPort, this.cruiseDays?.at(this.dayIndex)?.cruiseDayAtPort?.port)
        || !checkEquality(this.newRecord, this.initialRecord))) {
        return true
      }
      return !checkEquality(this.newRecord, this.initialRecord)
    },
    isDisembark() {
      return this.record?.header?.disembark
    },
    isEmbark() {
      return this.record?.header?.embark
    },
    isDayAtPortOrSea() {
      return !this.isEmbark && !this.isDisembark && !this.createNew
    },
    dayIndex() {
      return this.record?.header?.dayIndex
    },
    dayPortPlaceID() {
      return this.dayPort?.placeID
    },
    startPlaceID() {
      return  this.startPoint?.location?.placeID
    },
    endPlaceID() {
      return  this.endPoint?.location?.placeID
    },
    startPortLabel() {
      return this.isDayAtPortOrSea ? `${this.$t('Cruise_port')}` : `${this.$t('Embark_port')}*`
    },
    endPortLabel() {
      return this.isDayAtPortOrSea ? `${this.$t('Cruise_port')}` : `${this.$t('Disembark_port')}*`
    },
    startDateLabel() {
      if (this.isDayAtPortOrSea || this.isDisembark)
        return `${this.$t('Start_date')}*`
      else
        return `${this.$t('Embark_date')}*`
    },
    startTimeLabel() {
      if (this.isDayAtPortOrSea || this.isDisembark)
        return `${this.$t('Start_time')}*`
      return `${this.$t('Embark_time')}*`
    },
    endDateLabel() {
      if (this.isDayAtPortOrSea || this.isDisembark)
        return `${this.$t('End_date')}*`
      return `${this.$t('Disembark_date')}*`
    },
    endTimeLabel() {
      if (this.isDayAtPortOrSea || this.isDisembark)
        return `${this.$t('End_time')}*`
      return `${this.$t('Disembark_time')}*`
    },
    dialogLabel() {
      /*if (this.isEmbark)
        return this.$t('Cruise_embark')
      else */
      if (this.isDisembark)
        return this.$t('Cruise_disembark')
      else if (this.dayIndex)
        return this.$t('Cruise_day') + ': ' + (this.dayIndex + 1)
      else
        return this.$t('Tab_Details')
    },
    isEventValid() {
      return !!this.cruiseCompanyName
        && this.startPlaceID
        && this.isValidEndPointValue
    },
    isValidEndPointValue() {
      return this.isRoundTrip || this.endPlaceID
    },
    newRecord() {
      const dates = [this.startDate, this.startTime, this.endDate, this.endTime]
      const { startDateTime, endDateTime } = getDatesAndTimeAsISOString(...dates)

      let rv = {
        startDateTime: commonAPIs.momentDateTimeToStruct(this.$moment.parseZone(startDateTime)),
        endDateTime: commonAPIs.momentDateTimeToStruct(this.$moment.parseZone(endDateTime)),
        location: this.location,
        travelers: this.travelers,
        [this.eventType]: {
          ...(this.title) && { title: this.title },
          ...(this.notes) && { notes: this.notes },
          ...(this.isBooked) && { isBooked: this.isBooked },
          ...(this.cruiseShipName) && { cruiseShipName: this.cruiseShipName },
          ...(this.cruiseCompanyName) && { cruiseCompanyName: this.cruiseCompanyName },
          ...(this.cabinClass) && { cabinClass: this.cabinClass },
          ...(this.cabinNumber) && { cabinNumber: this.cabinNumber },
          ...(this.startPoint) && { embarkPoint: this.startPoint },
          ...(this.endPoint) && { disembarkPoint: this.endPoint },
          ...(this.cruiseDays) && { cruiseDays: this.cruiseDays },
          ...(convertAmountToServerFormat(this.totalPrice?.amount))
            && { totalPrice: {
              currency: this.totalPrice?.currency,
              amount: convertAmountToServerFormat(this.totalPrice?.amount)
            },
            },
        }
      }

      if (this.isDayAtPortOrSea || this.isDisembark) {
        rv.startDateTime = commonAPIs.momentDateTimeToStruct(this.$moment.parseZone(this.record?.cruise?.embarkPoint?.at))
        rv.endDateTime = commonAPIs.momentDateTimeToStruct(this.$moment.parseZone(this.record?.cruise?.disembarkPoint?.at))
        rv.cruise.embarkPoint = this.record?.cruise?.embarkPoint
        rv.cruise.disembarkPoint = this.record?.cruise?.disembarkPoint
      }

      return rv
    },
    endDateRules() {
      if (this.endPlaceID && this.endDate !== this.startDate) {
        return [this.dateTimeRules.dateTime]
      }
      return []
    },
    endTimeRules() {
      return this.endPlaceID ? [this.dateTimeRules.dateTime] : []
    },
  },
  methods: {
    updateDayAtSea(val) {
      this.isDayAtSea = val

      val && this.updatePortPoint() //reset port
    },
    async updateStartDate(val) {
      this.startDate = val
      await this.updateStartDateTime(val, this.startTime)
    },
    async updateStartTime(val) {
      this.startTime = val
      await this.updateStartDateTime(this.startDate, val)
    },
    async updateEndDate(val) {
      this.endDate = val
      await this.updateEndDateTime(val, this.endTime)
    },
    async updateEndTime(val) {
      this.endTime = val
      await this.updateEndDateTime(this.endDate, val)
    },
    async updateStartDateTime(date, time) {
      //date input in format 'YYYY-MM-DD'
      //time input in format 'HH:mm'
      time = time || '10:00'
      const dateTimeString = await datetime.convertDateTimeISOStringToLocalizedString(
        date + 'T' + time,
        this.startPlaceID
      )
      this.startPoint = {
        ...this.startPoint,
        at: dateTimeString
      }
      this.minDisembarkDateTime = dateTimeString
    },
    async updateEndDateTime(date, time) {
      //date input in format 'YYYY-MM-DD'
      //time input in format 'HH:mm'
      const defaultDuration = 4
      if (!time) {
        time = this.$moment(this.endTime, 'HH:mm').add(defaultDuration, 'hours').format('HH:mm')
      }

      const dateTimeString = await datetime.convertDateTimeISOStringToLocalizedString(
        date + 'T' + time,
        this.endPlaceID
      )
      this.endPoint = {
        ...this.endPoint,
        at: dateTimeString
      }
    },
    async updatePortPoint(val) {
      if (this.isDayAtPortOrSea) {
        this.dayPort = val
      }
    },
    async updateStartPoint(location) {
      this.startPoint = {
        ...this.startPoint,
        location: location
      }
      // this is needed for header of the event to be filled properly to pass the validation
      this.location = location
      if (this.isRoundTrip) {
        this.endPoint = this.startPoint
      }
    },
    async updateEndPoint(location) {
      this.endPoint = {
        ...this.endPoint,
        location: location
      }
    },
    updateRoundTrip(roundTrip) {
      this.isRoundTrip = roundTrip
      this.endPoint = roundTrip ? this.startPoint : this.endPoint
    },
    async mapDataToFormFields(eventData) {
      this.$emit('updateTitle', eventData?.title)
      this.isBooked = Boolean(eventData?.isBooked)
      this.notes = eventData?.notes
      this.totalPrice.amount = convertAmountToUIFormat(eventData?.totalPrice?.amount)
      this.totalPrice.currency = eventData?.totalPrice?.currency || this.userCurrency || 'USD'

      this.cruiseCompanyName = eventData?.cruiseCompanyName
      this.cruiseShipName = eventData?.cruiseShipName
      this.cabinNumber = eventData?.cabinNumber
      this.cabinClass = eventData?.cabinClass
      this.startPoint = eventData?.embarkPoint
      this.endPoint = eventData?.disembarkPoint
      this.cruiseDays = eventData?.cruiseDays || []

      if (this.isDayAtPortOrSea) {
        this.dayPort = this.cruiseDays?.at(this.dayIndex)?.cruiseDayAtPort?.port
        this.notes = this.cruiseDays?.at(this.dayIndex)?.notes
      } else if (this.isDisembark) {
        this.dayPort = this.cruiseDays?.at(this.dayIndex)?.cruiseDayAtPort?.port
        this.notes = this.cruiseDays?.at(this.dayIndex)?.notes
      }

      this.isRoundTrip = (this.startPoint === this.endPoint)
      this.isDayAtSea = !this.dayPortPlaceID
    },
    async updateCruiseDays() {
      const dates = [this.startDate, this.startTime, this.endDate, this.endTime]
      let { startDateTime, endDateTime } = getDatesAndTimeAsISOString(...dates)

      if (this.createNew && startDateTime && endDateTime) {
        this.cruiseDays = await cruise.synchronizeCruiseDays(startDateTime, endDateTime, this.startPoint, this.endPoint, this.cruiseDays)
      } else if (this.isDayAtPortOrSea || this.isDisembark) {
        const placeID = this.dayPort?.placeID || this.startPoint?.location?.placeID
        const cruiseDayUpdated =
          await cruise.createCruiseDay(
            await datetime.convertDateTimeISOStringToLocalizedString(startDateTime, placeID),
            await datetime.convertDateTimeISOStringToLocalizedString(endDateTime, placeID),
            this.dayPort,
            this.notes
          )
        this.cruiseDays = cruise.updateCruiseDays(this.cruiseDays, cruiseDayUpdated, this.dayIndex)
        this.notes = this.record?.cruise?.notes //preserve cruise level notes
      } else if (!this.createNew && startDateTime && endDateTime) {
        this.cruiseDays = await cruise.synchronizeCruiseDays(startDateTime, endDateTime, this.startPoint, this.endPoint, this.cruiseDays)
      }
    },
    async onClickSave() {
      try {
        await this.updateCruiseDays()
        this.$emit('save', this.newRecord)
        // added to skip isDirty check
        this.updateInitialRecord()

        this.$emit('close')
      } catch(err) {
        logAppError(err)
      }
    },

  },
  watch: {
    startDate: {
      handler(newVal, oldVal) {
        if (newVal && !oldVal && !this.endDate) {
          this.endDate = newVal
        }
      }, immediate: true
    },
    isDialogVisible: {
      async handler(visible) {
        if(visible && this.record) {
          await this.mapDataToFormFields(this.record[this.eventType])
        }
      },
      immediate: true
    },
  }
}
</script>

<style lang="scss" scoped>
.round-trip-text {
  & :deep(.v-input__control) {
    display: block;
  }
}
</style>
