<template>
  <div data-test-name="page-sign-up">
    <v-card
      v-if="successMessage"
      :max-width="isMobile? '100%' :  380"
      :height="isMobile? '100%': ''"
      class="mx-auto my-sm-10 pa-3"
      width="100%"
    >
      <v-col cols="12" align="center" class="pa-0 pb-4">
        <label class="text-body-1" v-html="successMessage" data-test-name="text-success-message" />
      </v-col>
      <v-col
        :key="headerButton.type"
        class="pa-0"
        cols="12"
        align="center"
      >
        <span data-test-name="text-description">{{ headerButton.description }}</span>
        <v-btn
          variant="text"
          color="primary"
          @click="goToSignInPage"
        >
          {{ headerButton.caption }}
        </v-btn>
      </v-col>
    </v-card>
    <authorization-page-common-wrapper
      v-else
      :applyButtonCaption="$t('Sign_up')"
      :applyButtonEnabled="isFormValid && !isUserCreating"
      :errorMessage="errorMessage"
      :headerButton="headerButton"
      @clickApply="signUpUser"
      @clickHeaderButton="handleclickHeaderButton"
      data-test-name="container-sign-up"
    >
      <template v-slot:content>
        <v-col cols="12" class="py-0 px-0 mt-3" align="center">
          <v-text-field
            autofocus
            color="primary"
            base-color="primary"
            bg-color="#fff"
            variant="outlined"
            prepend-inner-icon="$customUser"
            data-test-name="input-first-name"
            :model-value="firstName.value"
            :label="$t(firstName.label)"
            :error-messages="firstNameErrors"
            @blur="validateFirstName"
            @update:model-value="updateField('firstName', $event)"
            @keydown.enter="signUpUser"
          />
        </v-col>
        <v-col cols="12" class="py-0 px-0 mt-3" align="center">
          <v-text-field
            color="primary"
            base-color="primary"
            bg-color="#fff"
            variant="outlined"
            prepend-inner-icon="$customUser"
            data-test-name="input-last-name"
            :model-value="lastName.value"
            :label="$t(lastName.label)"
            :error-messages="lastNameErrors"
            @blur="validateLastName"
            @update:model-value="updateField('lastName', $event)"
            @keydown.enter="signUpUser"
          />
        </v-col>
        <v-col cols="12" class="py-0 px-0 mt-3" align="center">
          <v-text-field
            color="primary"
            base-color="primary"
            bg-color="#fff"
            variant="outlined"
            type="email"
            email
            prepend-inner-icon="$customEmail"
            data-test-name="input-email"
            :model-value="email.value"
            :label="email.label"
            :error-messages="emailErrors"
            @blur="validateEmail"
            @update:model-value="updateField('email', $event)"
            @keydown.enter="signUpUser"
          />
        </v-col>
        <v-col cols="12" class="py-0 px-0 mt-3" align="center">
          <v-text-field
            variant="outlined"
            color="primary"
            base-color="primary"
            bg-color="#fff"
            prepend-inner-icon="$customLock"
            data-test-name="input-password"
            :model-value="newPassword.value"
            :label="$t(newPassword.label)"
            :hint="$t(newPassword.hint)"
            :type="newPasswordInputType"
            :append-inner-icon="newPasswordAppendedIcon"
            :error-messages="newPasswordErrors"
            @click:append-inner="newPassword.isValueVisible = !newPassword.isValueVisible"
            @blur="validateNewPassword"
            @update:model-value="updateField('newPassword', $event)"
            @keydown.enter="signUpUser"
          />
        </v-col>
        <v-col cols="12" class="py-0 px-0 mt-3" align="center">
          <v-text-field
            variant="outlined"
            color="primary"
            base-color="primary"
            bg-color="#fff"
            prepend-inner-icon="$customLock"
            data-test-name="input-repeat-password"
            :model-value="repeatNewPassword.value"
            :label="$t(repeatNewPassword.label)"
            :hint="$t(repeatNewPassword.hint)"
            :type="repeatNewPasswordInputType"
            :append-inner-icon="repeatNewPasswordAppendedIcon"
            :error-messages="repeatNewPasswordErrors"
            @click:append-inner="repeatNewPassword.isValueVisible = !repeatNewPassword.isValueVisible"
            @blur="validateRepeatNewPassword"
            @update:model-value="updateField('repeatNewPassword', $event)"
            @keydown.enter="signUpUser"
          />
        </v-col>
      </template>
    </authorization-page-common-wrapper>
  </div>
</template>

<script>
import AuthorizationPageCommonWrapper from '@/components/authorization-page/AuthorizationPageCommonWrapper'
import cloneDeep from 'lodash.clonedeep'
import {
  firstName,
  lastName,
  email,
  newPassword,
  repeatNewPassword
} from '@/helpers/userProfileFields'
import { checkFilled, checkCorrect, checkMatch, checkHasEmptySpace, checkLength } from '@/helpers/inputValidators'
import { mapGetters } from 'vuex'
import {
  makeUserWithEmailAndPassword,
  updateUserProfile,
  sendUserEmailVerification,
} from '@/helpers/firebase/firebase'
import { RouteNames } from '@/helpers/routerUtils'
import { redirectToQueryPath } from '@/mixins/RedirectionMixin'
import { useRoute } from 'vue-router'
import { logAppError } from '@/logger'

export default {
  name: 'SignIn',
  components: {
    AuthorizationPageCommonWrapper
  },
  mixins: [redirectToQueryPath],
  data: () => ({
    firstName: {},
    lastName: {},
    email: {},
    newPassword: {},
    repeatNewPassword: {},
    successMessage: null,
    errorMessage: null,
    isUserCreating: false,
    route: useRoute(),
  }),
  created() {
    this.headerButton = {
      type: 'goToSignInPage',
      description:this.$t('Have_account_question'),
      caption:this.$t('Sign_in'),
      callback: this.goToSignInPage,
    }
    this.firstName = cloneDeep(firstName)
    this.lastName = cloneDeep(lastName)
    this.email = cloneDeep(email)
    this.newPassword = cloneDeep(newPassword)
    this.repeatNewPassword = cloneDeep(repeatNewPassword)
  },
  computed: {
    ...mapGetters({
      isMobile: 'IS_MOBILE',
      isAnonymous: 'IS_ANONYMOUS',
    }),
    isFormValid() {
      return !!(this.firstName.value.length && this.firstName.errors.length === 0) &&
        (this.lastName.value.length > 0 && this.lastName.errors.length === 0) &&
        (this.email.value.length > 0 && this.email.errors.length === 0) &&
        (this.newPassword.value.length > 0 && this.newPassword.errors.length === 0) &&
        (this.repeatNewPassword.value.length > 0 && this.repeatNewPassword.errors.length === 0) &&
        (this.repeatNewPassword.value === this.newPassword.value)
    },
    preparedFirstName() {
      return (this.firstName.value || '').trim()
    },
    preparedLastName() {
      return (this.lastName.value || '').trim()
    },
    preparedEmail() {
      return (this.email.value || '').trim().toLowerCase()
    },
    firstNameErrors(){
      return this.firstName.blurred ? this.firstName.errors.map(message => this.$t(message)) : []
    },
    lastNameErrors(){
      return this.lastName.blurred ? this.lastName.errors.map(message => this.$t(message)) : []
    },
    emailErrors(){
      return this.email.blurred ? this.email.errors.map(message => this.$t(message)) : []
    },
    newPasswordAppendedIcon(){
      return this.newPassword.isValueVisible ? 'mdi-eye-outline' : 'mdi-eye-off-outline'
    },
    repeatNewPasswordAppendedIcon(){
      return this.repeatNewPassword.isValueVisible ? 'mdi-eye-outline' : 'mdi-eye-off-outline'
    },
    newPasswordInputType(){
      return this.newPassword.isValueVisible ? 'text' : 'password'
    },
    repeatNewPasswordInputType(){
      return this.repeatNewPassword.isValueVisible ? 'text' : 'password'
    },
    newPasswordErrors(){
      return this.newPassword.blurred ? this.newPassword.errors.map(message => this.$t(message)) : []
    },
    repeatNewPasswordErrors(){
      return this.repeatNewPassword.blurred ? this.repeatNewPassword.errors.map(message => this.$t(message)) : []
    }
  },
  methods: {
    validateFirstName(){
      const errors = []
      this.firstName.blurred = true
      errors.push(checkFilled(this.firstName))
      errors.push(checkCorrect(this.firstName))
      errors.push(checkLength(this.firstName))
      this.firstName.errors = errors.filter(error => !!error)
    },
    validateLastName(){
      const errors = []
      this.lastName.blurred = true
      errors.push(checkFilled(this.lastName))
      errors.push(checkCorrect(this.lastName))
      errors.push(checkLength(this.lastName))
      this.lastName.errors = errors.filter(error => !!error)
    },
    validateEmail(){
      const errors = []
      this.email.blurred = true
      errors.push(checkFilled(this.email))
      errors.push(checkCorrect(this.email))
      this.email.errors = errors.filter(error => !!error)
    },
    validateNewPassword(){
      const errors = []
      this.newPassword.blurred = true
      errors.push(checkHasEmptySpace(this.newPassword))
      errors.push(checkFilled(this.newPassword))
      errors.push(checkCorrect(this.newPassword))
      this.newPassword.errors = errors.filter(error => !!error)
    },
    validateRepeatNewPassword(){
      const errors = []
      this.repeatNewPassword.blurred = true
      errors.push(checkHasEmptySpace(this.repeatNewPassword))
      errors.push(checkFilled(this.repeatNewPassword))
      errors.push(checkCorrect(this.repeatNewPassword))
      errors.push(checkMatch(this.newPassword, this.repeatNewPassword))
      this.repeatNewPassword.errors = errors.filter(error => !!error)
    },
    updateField(fieldName, value) {
      this[fieldName].value = value
      this[fieldName].blurred = false
      this.errorMessage = null
    },
    async signUpUser() {
      if(this.isFormValid) {
        this.isUserCreating = true
        try {
          await makeUserWithEmailAndPassword(this.preparedEmail, this.newPassword.value)
          await this.updateFirebaseProfile()
          await this.sendVerificationEmail()
        } catch(error) {
          logAppError(error)
          if(error.code === 'auth/email-already-in-use') {
            this.errorMessage = this.$t('Non_unique_email_message')
          } else {
            this.errorMessage = this.$t('Sign_up_error_message')
          }
        } finally {
          this.isUserCreating = false
        }
      }
    },
    async updateFirebaseProfile() {
      const profile = {
        displayName: `${this.preparedFirstName} ${this.preparedLastName}`
      }
      return await updateUserProfile(profile)
    },
    async sendVerificationEmail() {
      await sendUserEmailVerification(this.route?.query)
      this.successMessage =  this.$t('Resend_email_message', { email: this.email?.value })
    },
    goToSignInPage() {
      this.$router.push({name: RouteNames.SIGN_IN, query: this.route?.query})
    },
    handleclickHeaderButton(callback) {
      callback()
    }
  }
}
</script>
