<template>
  <div class="itinerary-header" data-test-name="content-itinerary-header" @click="headerClicked"
  >
    <div
      class="d-flex align-center px-4 py-2 itinerary-header-panel"
      :class="{'itinerary-header-panel--mobile': $vuetify.display.xs}"
    >
      <v-row v-if="!$vuetify.display.xs" class="itinerary-header__row d-flex align-center justify-start">
        <v-col cols="1" sm="2" class="d-flex justify-center align-center">
          <div
            v-if="!isTemplateView"
            class="d-flex flex-column font-weight-bold text-body-1"
            data-test-name="text-date"
          >
            <span v-if="!isInsuranceEvent">{{formatDateTime(startDTM, 'ddd')}}, {{formatDateTime(startDTM, 'MMM')}} {{formatDateTime( startDTM, 'DD')}}</span>
          </div>
        </v-col>
        <v-col cols="1" sm="2" class="d-flex justify-start">
          <div class="ml-1 d-flex flex-column">
            <span class="mb-n1 pb-2 text-subtitle-2" data-test-name="text-start-end-time">
              {{ formatTime(startDTM, endDTM) }}
            </span>
            <span class="mb-n1 text-subtitle-2" data-test-name="text-overnight-message">
              {{ overnightMessage }}
            </span>
          </div>
        </v-col>
        <v-col
          cols="7"
          sm="3"
          md="4"
          class="d-flex align-center itinerary-header__title"
        >
          <div>
            <v-icon size="20" class="mr-2" :color="iconColor" :icon="icon" data-test-name="icon" />
          </div>
          <div
            v-if="title"
            class="text-truncate pa-0 py-1 text-h6 font-weight-bold"
            data-test-name="text-title-origin-destination"
          >
            {{ title }}
          </div>
        </v-col>
        <v-col :cols="!isTemplateView ? 1 : 2">
          <span
            class="font-weight-regular text-subtitle-2 itinerary-header__duration"
            data-test-name="text-duration"
          >
            {{ duration }}
          </span>
        </v-col>
        <v-col v-if="!isTemplateView" cols="1" sm="2" md="1" class="pa-0 my-0">
          <v-row class="ma-0">
            <v-col cols="12" class="d-flex justify-end text-subtitle-2 pa-0">
              <colored-price
                :totalPrice="totalPrice"
              />
            </v-col>
            <v-col cols="12" class="text-caption text-end pa-0 text--secondary" data-test-name="text-converted-price"
            >
              {{ convertedPrice }}
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="1" sm="2" class="d-flex justify-end pr-lg-8">
          <v-icon
            v-if="directionsLink"
            color="primary"
            class="mr-4"
            size="small"
            data-test-id="map-button"
            data-test-name="button-open-map"
            @click.stop="proceedToMap"
            icon="$customMap"
          />
          <span
            :style="{'visibility': !isTemplateView ? 'visible' : 'hidden'}"
            class="d-flex flex-row flex-nowrap"
            data-test-id="comment-button"
            data-test-name="button-open-comments-popup"
            @click.stop="toggleComments"
            v-if="isDisplayComments"
          >
            <v-icon
              color="primary"
              class="mr-1"
              size="small"
              icon="$customComment"
            />
            <unread-comment-counter
              v-if="unreadCommentsCount > 0"
              class="text-subtitle-2 bg-primary text-textSecondaryColor itinerary__item--unread"
              :unread-comments-count="unreadCommentsCount"
            />
          </span>
          <v-icon
            :style="{'visibility': !isTemplateView && (isOwner || isEditor) && canShowDelete ? 'visible' : 'hidden'}"
            class="ml-4"
            size="small"
            @click.stop="showDialogToDeleteEvent"
            icon="$customDelete"
            data-test-name="button-open-delete-event-dialog"
          />
        </v-col>
      </v-row>
      <v-row v-else no-gutters
        class="d-flex align-start justify-start mx-0">
        <v-col cols="12" class="d-flex px-0">
          <v-col cols="5" class="px-0 px-sm-2 d-flex justify-sm-start align-sm-start flex-sm-column">
            <div
              v-if="!isTemplateView"
              class="d-flex flex-sm-column font-weight-bold text-body-2"
              data-test-name="text-date"
            >
              <span v-if="!isInsuranceEvent">{{formatDateTime(startDTM, 'ddd')}}, {{formatDateTime(startDTM, 'MMM')}} {{formatDateTime( startDTM, 'DD')}}</span>
            </div>
          </v-col>
          <v-col cols="6" sm="5" class="px-0 d-flex flex-sm-column justify-end text-right">
            <div class="px-0 text-body-2 font-weight-bold text-no-wrap" data-test-name="text-start-end-time">
              {{ formatTime(startDTM, endDTM) }}
            </div>
          </v-col>
          <v-col
            cols="2"
            class="pr-0 pl-auto"
          >
            <v-icon
              v-if="$vuetify.display.xs && !isTemplateView"
              @click.stop="isVisibleEventMenu = true"
              color="primary"
              size="18"
              class="mr-1"
              icon="mdi-dots-vertical"
              data-test-name="icon"
            />
          </v-col>
        </v-col>
        <v-col cols="12" class="d-flex align-center justify-start pr-2">
          <v-icon size="20" class="mr-2" data-test-name="icon" :color="iconColor" :icon="icon" />
          <span
            v-if="title"
            class="pa-0 text-body-2 truncate-ellipsis font-weight-bold event-title"
            data-test-name="text-title-origin-destination"
          >
            {{ title }}
          </span>
        </v-col>
        <v-col cols="12">
          <v-row no-gutters>
            <v-col cols="12" class="px-0 d-flex justify-space-between">
              <v-col cols="8" class="px-0 pb-2 pb-sm-4 d-flex text-left">
                <span class="text-body-2" data-test-name="text-duration">{{ duration }}</span>
                <v-chip
                  v-if="overnightMessage"
                  size="x-small"
                  color="lighten-3"
                  class="ml-4 mt-1 text-caption d-inline-block text-center font-regular"
                >
                  <span class="mx-1" data-test-name="text-overnight-message">{{ overnightMessage }}</span>
                </v-chip>
              </v-col>
              <v-col cols="4" class="px-0 pb-2 pb-sm-4">
                <v-row v-if="!isTemplateView" no-gutters>
                  <v-col cols="12" class="py-0 d-flex justify-end text-body-2 font-weight-bold">
                    <colored-price
                      :totalPrice="totalPrice"
                    />
                  </v-col>
                  <v-col cols="12" class="py-0 text-caption text-end text--secondary" data-test-name="text-converted-price">
                    {{ convertedPrice }}
                  </v-col>
                </v-row>
              </v-col>
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col v-if="!$vuetify.display.xs" cols="12" class="d-flex justify-center">
              <v-col
                v-if="!isTemplateView && isDisplayComments"
                cols="3"
                class="d-flex justify-center align-center"
                data-test-id="comment-button"
                @click.stop="toggleComments"
              >
                <v-icon color="primary" size="small" class="mr-1" icon="$customComment"/>
                <unread-comment-counter
                  v-if="unreadCommentsCount > 0"
                  class="bg-primary text-textSecondaryColor itinerary__item--unread"
                  :unread-comments-count="unreadCommentsCount"
                />
              </v-col>
              <v-col v-if="directionsLink" cols="3" class="d-flex justify-center align-center">
                <v-icon
                  color="primary"
                  size="small"
                  class="mr-4"
                  data-test-id="map-button"
                  @click.stop="proceedToMap"
                  icon="$customMap"
                  data-test-name="button-open-map"
                />
              </v-col>
              <v-col v-if="!isTemplateView && (isOwner || isEditor)" cols="3" class="d-flex justify-center align-center">
                <v-icon
                  size="small"
                  class="mr-4"
                  @click.stop="showDialogToDeleteEvent"
                  icon="$customDelete"
                  data-test-name="button-open-delete-event-dialog"
                />
              </v-col>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <comment-window
        v-if="isCommentsWindowVisible"
        :itemID="itemID"
        :itineraryID="itineraryID"
        data-test-id="comment-window"
        @close="toggleComments"
      />
    </div>
    <itinerary-template-popup
      v-if="isVisibleTemplateModal"
      :title="title"
      :link="link"
      :description="description"
      :imageURL="imageURL"
      :address="address"
      :confirm-label="$t('Close')"
      :readonly="true"
      @close="closeTemplateEventDialog"
    />
    <common-popup
      v-if="isDialogToDeleteEventVisible"
      :body-content="$t('Delete_this_event')"
      :confirm-label="$t('Delete')"
      :cancel-label="$t('Cancel')"
      @confirm="removeItem()"
      @cancel="closeDialogToDeleteEvent"
      @close="closeDialogToDeleteEvent"
    />
    <v-dialog
      :model-value="isEventDialogOpened"
      :fullscreen="isMobile"
      max-width="75rem"
      @update:model-value="handleEventDialogChange"
      @click:outside="$refs.eventDialog.cancelChanges()"
    >
      <event-dialog
        :isOpened="isEventDialogOpened"
        :isCreateMode="false"
        :event="selectedEvent"
        :record="record"
        scrollable
        @checkFormState="isDirty = $event.dirty"
        @cancel="cancelEventDialogChanges"
        @close="closeEventDialog"
        ref="eventDialog"
      />
    </v-dialog>
    <common-popup
      v-if="confirmationPopupVisible"
      :body-content="$t('Question_to_discard_changes')"
      :confirm-label="$t('Discard')"
      :cancel-label="$t('Keep_editing')"
      @confirm="closeConfirmationPopup(true)"
      @cancel="closeConfirmationPopup"
      @close="closeConfirmationPopup"
    />

    <event-menu
      v-if="isVisibleEventMenu"
      :titleContent="$t('Event_actions')"
      @close="isVisibleEventMenu = false"
    >
      <v-col
        v-if="!isTemplateView"
        cols="12"
        class="pl-0 py-6 d-flex justify-left align-center"
        data-test-id="comment-button"
        @click.stop="toggleComments"
        data-test-name="button-open-comments-popup"
      >
        <v-icon color="primary" size="18" class="mr-2" data-test-name="icon-comments">
          {{ '$customComment' }}
        </v-icon>
        <unread-comment-counter
          v-if="isOwner && unreadCommentsCount > 0"
          class="bg-primary text-textSecondaryColor itinerary__item--unread"
          :unread-comments-count="unreadCommentsCount"
        />
        <h3 class="pl-4 text-primary font-weight-medium" data-test-name="text-label-comments">{{ $t('Comments') }}</h3>
      </v-col>
      <v-divider class="pb-2 divider"></v-divider>
      <v-col
        v-if="directionsLink"
        cols="12"
        @click.stop="proceedToMap"
        class="pl-0 py-6 d-flex justify-left align-center"
        data-test-name="button-open-map"
      >
        <v-icon
          color="primary"
          size="16"
          class="mr-2"
          data-test-id="map-button"
          icon="$customMap"
          data-test-name="icon-map"
        />
        <h3 class="pl-4 text-primary font-weight-medium" data-test-name="text-view-on-map">
          {{ $t('View_on_map') }}
        </h3>
      </v-col>
      <v-divider v-if="directionsLink" class="pb-2 divider"></v-divider>
      <v-col
        v-if="!isTemplateView && (isOwner || isEditor)"
        cols="12"
        @click.stop="showDialogToDeleteEvent"
        class="pl-0 py-6 d-flex justify-left align-center"
        data-test-name="button-open-delete-event-dialog"
      >
        <v-icon size="16" color="error" class="mr-2" icon="$customDelete" data-test-name="icon-delete" />
        <h3 class="pl-4 text-error font-weight-medium" data-test-name="text-subtitle-delete">{{ $t('Delete') }}</h3>
      </v-col>
    </event-menu>
  </div>
</template>

<script>
import itineraryService from '@/helpers/itineraryService'
import { convertToUserCurrency } from '@/helpers/currency'
import commonPopup from '@/components/popups/CommonPopup'
import { mapGetters } from 'vuex'
import commonAPIs from '@/helpers/itineraryServices/commonAPIs'
import CommentWindow from '@/components/popups/comment/CommentWindow'
import UnreadCommentCounter from '@/components/popups/comment/UnreadCommentCounter'
import EventDialog from '@/components/calendar/EventDialog.vue'
import ItineraryTemplatePopup from '@/components/popups/template/ItineraryTemplatePopup'
import ColoredPrice from '@/components/itinerary-edit/ColoredPrice'
import { findEventByType, getItemTypeByType, formatPrice, EventTypes } from '@/helpers'
import EventMenu from '@/components/popups/EventMenu'
import { useRoute } from 'vue-router'
import { getGoogleImageUrl } from '@/helpers/imageUtil'

export default {
  name: 'ItineraryHeader',
  components: {
    ItineraryTemplatePopup,
    commonPopup,
    CommentWindow,
    UnreadCommentCounter,
    EventDialog,
    ColoredPrice,
    EventMenu
  },
  data() {
    return {
      convertedPrice: '',
      isCommentsWindowVisible: false,
      isDialogToDeleteEventVisible: false,
      isEventDialogOpened: false,
      isVisibleTemplateModal: false,
      isVisibleEventMenu: false,
      dialogWidth: {
        maxWidth: '800px'
      },
      route: useRoute(),
      isDirty: false,
      confirmationPopupVisible: false,
    }
  },
  props: {
    record: {
      type: Object,
    },
    itemID: {
      type: String,
      required: true
    },
    totalPrice: {
      type: Object
    },
    startDTM: String,
    endDTM: String,
    title: String,
    duration: String,
    icon: String,
    iconColor: String,
    eventType: { type: String, required: true },
    directionsLink: String,
    nightAtHotel: { type: Boolean, default: false },
    checkOut: { type: Boolean, default: false },
    readonly: { type: Boolean, default: false },
    overnightMessage: { type: String, default: ''},
    isTemplateView: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters({
      isMobile: 'IS_MOBILE',
      userCurrency: 'CURRENCY',
      user: 'USER',
      currentItinerary: 'CURRENT_ITINERARY',
      isOwner: 'IS_OWNER',
      isEditor: 'IS_EDITOR',
      itineraryComments: 'COMMENTS',
    }),
    link() {
      return this.record[this.eventType].link || ''
    },
    description() {
      return this.record[this.eventType].description && this.record[this.eventType].description.text || ''
    },
    imageURL() {
      let path
      let rv

      switch (this.eventType) {
      case EventTypes.RESTAURANT:
        path = this.record[this.eventType]?.roData?.googleImages
        rv = getGoogleImageUrl(path) || getItemTypeByType(this.eventType).defaultImageUrl
        break
      default:
        rv = this.record[this.eventType]?.roData?.thumbnailURL
        break
      }
      return rv
    },
    address() {
      return this.record[this.eventType]?.address
    },
    unreadCommentsCount() {
      const unreadComments = this.itineraryComments[this.itineraryID]?.itemsComments[this.itemID]?.unread
      return unreadComments?.length || 0
    },
    itineraryID() {
      return this.route.params?.id
    },
    isInsuranceEvent() {
      return this.eventType === EventTypes.INSURANCE
    },
    selectedEvent() {
      return findEventByType(this.eventType)
    },
    canShowDelete() {
      switch (this.eventType) {
      case EventTypes.LODGING:
        return !this.nightAtHotel && !this.checkOut
      case EventTypes.CRUISE:
        return this.record?.header?.embark
      default:
        return true
      }
    },
    isDisplayComments() {
      switch (this.eventType) {
      case EventTypes.LODGING:
        return !this.nightAtHotel && !this.checkOut
      case EventTypes.CRUISE:
        return this.record?.header?.embark
      default:
        return true
      }
    },
  },
  methods: {
    headerClicked() {
      if (this.isTemplateView) {
        this.openTemplateEventDialog()
      } else {
        this.openEventDialog()
      }
    },
    openTemplateEventDialog() {
      this.isVisibleTemplateModal = true
    },
    closeTemplateEventDialog() {
      this.isVisibleTemplateModal = false
    },
    handleEventDialogChange() {
      this.cancelEventDialogChanges()
    },
    openEventDialog() {
      this.isEventDialogOpened = true
    },
    closeEventDialog() {
      this.isEventDialogOpened = false
    },
    cancelEventDialogChanges() {
      if (this.isDirty) {
        this.confirmationPopupVisible = true
      } else {
        this.isEventDialogOpened = false
      }
    },
    openConfirmationPopup() {
      this.confirmationPopupVisible = true
    },
    closeConfirmationPopup(closeEventDialog = false) {
      if (closeEventDialog) {
        this.closeEventDialog()
      }

      this.confirmationPopupVisible = false
      this.isDirty = false
    },
    showDialogToDeleteEvent() {
      this.isDialogToDeleteEventVisible = true
    },
    closeDialogToDeleteEvent() {
      this.isDialogToDeleteEventVisible = false
    },
    async removeItem() {
      try {
        if (this.eventType === EventTypes.INSURANCE) {
          await commonAPIs.removeInsurance(
            this.currentItinerary.header.itineraryID,
            this.user.id,
            this.itemID,
          )
        } else {
          await commonAPIs.removeItem(
            this.currentItinerary.header.itineraryID,
            this.user.id,
            this.itemID,
          )
        }
      } catch (e) {
        this.isDialogToDeleteEventVisible = false
      }
    },
    proceedToMap() {
      if (!this.directionsLink) return
      window.open(this.directionsLink, '_blank')
    },
    async convertPrice() {
      if (this.totalPrice?.amount && this.totalPrice?.currency) {
        const convertedAmount = await convertToUserCurrency(this.totalPrice?.amount, this.totalPrice?.currency, this.userCurrency)
        this.convertedPrice = (this.totalPrice?.currency !== this.userCurrency)
          ? formatPrice({ amount: convertedAmount, currency: this.userCurrency }) : ''
      } else {
        this.convertedPrice = ''
      }
    },
    formatTime(start, end) {
      switch (this.eventType) {
      case EventTypes.LODGING:
        if (this.nightAtHotel) return 'Night at'
        if (this.checkOut) return `${this.$t('CheckOut')}  ${itineraryService.formatTime(this.isTemplateView ? end : start)}`
        return `${this.$t('CheckIn')} ${itineraryService.formatTime(start)}`
      case EventTypes.CRUISE:
        if (this.record?.header?.disembark)
          return `${this.$t('Cruise_disembark')} ${itineraryService.formatTime(this.isTemplateView ? end : start)}`
        if (this.record?.header?.embark)
          return `${this.$t('Cruise_embark')} ${itineraryService.formatTime(start)}`
        return `${itineraryService.formatTime(start)} - ${itineraryService.formatTime(end)}`
      case EventTypes.INSURANCE:
        return ''
      default:
        return `${itineraryService.formatTime(start)} - ${itineraryService.formatTime(end)}`
      }
    },
    toggleComments() {
      this.isCommentsWindowVisible = !this.isCommentsWindowVisible
    },
    formatDateTime: itineraryService.formatDateTime
  },
  watch: {
    totalPrice: {
      async handler() {
        await this.convertPrice()
      },
      immediate: true
    },
  },
}
</script>

<style lang="scss" scoped>
.v-expansion-panel-title--active::before {
  opacity: 0 !important;
}

.desc-wrapper {
  width: 100%;
  padding-right: 0.75rem;
}

.event-title {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.itinerary {
  &-header {
    cursor: pointer;

    &__row {
      width: 100%;
    }

    &__title:hover {
      color: rgb(var(--v-theme-primary));
      text-decoration: underline;
    }

    &__date {
      color: rgb(var(--v-theme-secondary)) !important;
    }

    &-panel {
      height: 83px;
      background: rgba(#0144C7, .05);

      &--mobile {
        height: auto;
      }

      &__date {
        color: rgb(var(--v-theme-secondary));
      }
    }

    &__date {
      color: rgb(var(--v-theme-primary));
    }

    &__duration {
      color: rgb(var(--v-theme-secondary));
    }

  }
  &__item {
    &--unread {
      position: relative;
      right: 2px;
      bottom: 10px;
    }
    &--button:hover {
      cursor: pointer;
    }
  }
}
</style>
